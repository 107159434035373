import request from '@/utils/request';
import CryptoJS from 'crypto-js';
export function login(username: any, password: any) {
  password = CryptoJS.MD5(password).toString();
  return request({
    url: '/card-server/server/support/login',
    method: 'post',
    data: {
      username,
      password,
    },
  });
}

export function getInfo() {
  return request({
    url: '/card-server/server/support/info',
    method: 'post',
  });
}

export function logout() {
  return request({
    url: '/card-server/server/support/logout',
    method: 'post',
  });
}

export function getTIMUserSign() {
  return request({
    url: '/card-server/IM/support/getUserSign',
    method: 'post',
  });
}

export function switchOpenRoute() {
  return request({
    url: '/card-server/server/support/switchOpenRoute',
    method: 'post',
  });
}

export function switchCloseRoute() {
  return request({
    url: '/card-server/server/support/switchCloseRoute',
    method: 'post',
  });
}
