import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LayOut from '@/layouts/LayoutIndex.vue';
import process from 'process';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/login/PageIndex.vue'),
  },
  {
    path: '/',
    component: LayOut,
    name: 'page',
    meta: { title: '首页', icon: 'HomeFilled' },
  },
  {
    path: '/wokerOrderManage',
    component: LayOut,
    redirect: '/wokerOrderManage/serverManage',
    name: 'wokerOrderManage',
    meta: { title: '工单列表', icon: 'TrademarkOutlined' },
    children: [
      {
        path: 'serverManage',
        name: 'serverManage',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/wokerOrderManage/ServerIndex.vue'
          ),
        meta: { title: '工单列表', icon: 'TrademarkOutlined' },
      },
    ],
  },
  {
    path: '/chatManage',
    component: LayOut,
    redirect: '/chatManage/chat',
    name: 'chatManage',
    meta: { title: '客服聊天', icon: 'UnorderedListOutlined' },
    children: [
      {
        path: 'chat',
        name: 'chat',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/chat/PageIndex.vue'),
        meta: { title: '客服聊天', icon: 'UnorderedListOutlined' },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
