// date.js
export function formatDate(date: any, fmt: any) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}
 export function timestampToDateTime(timestamp) {
  var date = new Date(Number(timestamp));  // 将时间戳转换为Date对象
  console.log(date,'year')
  var year = date.getFullYear();   // 获取年份
  var month = date.getMonth() + 1;  // 获取月份，需要+1因为月份是从0开始计算的
  var day = date.getDate();         // 获取日
  var hours = date.getHours();      // 获取小时
  var minutes = date.getMinutes();  // 获取分钟
  var seconds = date.getSeconds();  // 获取秒钟
 
  // 格式化月份和日期，如果小于10，则前面加0
  let M = (month < 10 ? "0" : "") + month.toString();
  let d = (day < 10 ? "0" : "") + day.toString();
  let h = (hours < 10 ? "0" : "") + hours.toString();
  let m = (minutes < 10 ? "0" : "") + minutes.toString();
  let s = (seconds < 10 ? "0" : "") + seconds.toString();
 
  // 拼接字符串返回
  return year + "-" + M + "-" + d + " " + h + ":" + m + ":" + s;
}
//时分秒倒计时
export function countdown(timestamp: any) {
  // let time = new Date(timestamp);
  let hours: any = Math.floor(timestamp / 3600000);
  let minute: any = Math.floor((timestamp - hours * 3600000) / 60000);
  let second: any = Math.floor(
    (timestamp - hours * 3600000 - minute * 60000) / 1000
  );
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minute < 10) {
    minute = "0" + minute;
  }
  if (second < 10) {
    second = "0" + second;
  }
  return hours + ":" + minute + ":" + second;
}
function padLeftZero(str: any) {
  return ("00" + str).substr(str.length);
}

export function str2Date(dateStr: any, separator: any) {
  if (!separator) {
    separator = "-";
  }
  let dateArr = dateStr.split(separator);
  let year = parseInt(dateArr[0]);
  let month;
  //处理月份为04这样的情况
  if (dateArr[1].indexOf("0") == 0) {
    month = parseInt(dateArr[1].substring(1));
  } else {
    month = parseInt(dateArr[1]);
  }
  let day = parseInt(dateArr[2]);
  let date = new Date(year, month - 1, day);
  return date;
}
