const getters = {
  sidebar: (state: any) => state.app.sidebar,
  device: (state: any) => state.app.device,
  token: (state: any) => state.user.token,
  avatar: (state: any) => state.user.avatar,
  name: (state: any) => state.user.name,
  roles: (state: any) => state.user.roles,
  exchangeAmount: (state: any) => state.user.exchangeAmount,
  addRouters: (state: any) => state.permission.addRouters,
  routers: (state: any) => state.permission.routers,
  userID: (state: any) => state.user.userID,
  TIMsign: (state: any) => state.user.TIMsign,
  TIMcode: (state: any) => state.user.TIMcode,
  userInfo: (state: any) => state.user.userInfo,
};
export default getters;
